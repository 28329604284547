/* global Image */

// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'query-string';

import React, {
  Component,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import styled, { css } from 'styled-components';

import AntdSelect from 'antd/lib/select';
import AntdButton from 'antd/lib/button';
import {
  CameraOutlined,
  DownOutlined,
  UpOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

import Query from 'hive-admin/src/components/Query';

import EditorContext from '../../Context';
import Popover, {
  Content as PopoverContent,
} from '../../../../Popover';

import Types from '../../../../../modules/types';

const Actions = styled.div`
  display: flex;
`;

export const Icon = styled.div`
  display: flex;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  transform: translateY(2px);
  line-height: 1;
  &[data-active="true"] {
    font-weight: 600;
  }
  &:hover, &.ant-popover-open, &[data-active="true"] {
    color: ${({ theme }) => theme.less.primaryColor};
  }
`;

export const Select = styled(AntdSelect)`
  width: 100%;
`;

// const CountriesSelect = styled(Select)`
//   width: 100%;
// `;

// function CountriesInput({
//   value,
//   onChange,
// }) {
//   return (
//     <CountriesSelect
//       value={value}
//       onChange={onChange}
//       allowClear
//       placeholder="Countries"
//       optionFilterProp="children"
//       mode="multiple"
//     >
//       {Types.COUNTRIES.map(({ name: label, iso3a2 }) => (
//         <CountriesSelect.Option
//           key={iso3a2}
//           value={iso3a2}
//         >
//           {label}
//         </CountriesSelect.Option>
//       ))}
//     </CountriesSelect>
//   );
// }

const PreviewIconHTML = ({ disabled, ...props }) => (
  <CameraOutlined data-disabled={!!disabled} {...props} />
);

export const PreviewIcon = styled(PreviewIconHTML)`
  font-size: 16px;
  padding: 5px;
  margin-right: -5px;
  margin-left: 8px;
  margin-top: -5px;
  margin-bottom: -5px;
  line-height: 1;
  cursor: pointer;
  ${({ disabled }) => disabled && css`
    opacity: 0.3;
    pointer-events: none;
  `}
  &:not([data-disabled="true"]) {
    &:hover, &.ant-popover-open {
      color: ${({ theme }) => theme.less.primaryColor};
    }
  }
`;

const PreviewImageHTML = ({ src, width, height, ...props }) => (
  <div {...props} />
);

export const PreviewImage = styled(PreviewImageHTML)`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${({ src, width, height }) => css`
    background-image: url("${src}");
    width: ${width}px;
    height: ${height}px;
  `}
`;

export function Preview({ src }) {
  const [isVisible, setIsVisible] = useState(false);
  const [imageSize, setImageSize] = useState(null);
  useEffect(
    () => {
      setImageSize(null);
      const image = new Image();
      if (src) {
        image.onload = () => {
          if (!image.cancelled) {
            const index = 350 / Math.max(
              image.naturalWidth,
              image.naturalHeight,
            );
            setImageSize({
              width: image.naturalWidth * index,
              height: image.naturalHeight * index,
            });
          }
        };
        image.src = src;
      }
      return () => { image.cancelled = true; };
    },
    [src, setImageSize],
  );
  if (!src) {
    return (
      <PreviewIcon disabled />
    );
  }
  return (
    <Popover
      content={(
        imageSize
        ? <PreviewImage {...imageSize} src={src} />
        : null
      )}
      trigger="hover"
      visible={imageSize && isVisible}
      onVisibleChange={visible => setIsVisible(visible)}
    >
      <PreviewIcon />
    </Popover>
  );
}

export class POSMaterialPositionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: undefined,
      // countries: undefined,
    };
  }

  getProjectPosMaterials() {
    const { projectPosMaterials } = this.props;
    const { search } = this.state;
    if (!search || !search.length) {
      return projectPosMaterials;
    }
    return projectPosMaterials.filter(({ name }) => (
      name.toLowerCase().indexOf(search.toLowerCase()) > -1
    ));
  }

  getQueryUrl() {
    const {
      search,
      // countries,
    } = this.state;
    const {
      region,
      organization,
      position,
    } = this.props;
    return `library/posmaterials?${qs.stringify({
      query: JSON.stringify({
        limit: 20,
        skip: 0,
        sort: { name: -1 },
        // where: Object.assign(
        //   { type: Types.POS_MATERIAL_POSITION_TYPE_MAP[position] },
        //   (
        //     countries && countries.length
        //     ? { countries: { IN: countries } }
        //     : {}
        //   ),
        //   (
        //     search && search.length
        //     ? { name: { REGEX: search, OPTIONS: 'i' } }
        //     : {}
        //   ),
        //   {
        //     organization: {
        //       IN: (
        //         organization
        //         ? [null, organization]
        //         : [null]
        //       ),
        //     },
        //   }
        // ),
        where: {
          AND: [
            {
              type: Types.POS_MATERIAL_POSITION_TYPE_MAP[position],
              organization: {
                IN: (
                  organization
                  ? [null, organization]
                  : [null]
                ),
              },
            },
            // (
            //     countries && countries.length
            //   ? {
            //       OR: [
            //         { countries: { IN: countries } },
            //         { countries: { EQ: [] } },
            //         { countries: { EQ: null } },
            //       ],
            //     }
            //   : null
            // ),
            (
              region
              ? { region }
              : null
            ),
            (
                search && search.length
              ? { name: { REGEX: search, OPTIONS: 'i' } }
              : null
            ),
          ].filter(condition => !!condition),
        },
      }),
    })}`;
  }

  extractQueryData = response => (
    (
      response
      && response.data
      && response.data.data
      && response.data.data.length
    )
    ? response.data.data
    : []
  )

  handleSearchChange = search => this.setState({ search })

  // handleCountriesChange = countries => this.setState({ countries })

  handleChange = (value) => {
    const { id, position, value: shelf, onChange } = this.props;
    onChange(id, {
      posMaterials: {
        ...shelf.posMaterials,
        [position]: value || null,
      },
    });
  }

  renderContent() {
    const {
      position,
      getPosMaterial,
      value: { posMaterials },
      disabled,
      onApplyInDirection,
      supportsApplyHorizontally = true,
      supportsApplyVertically = true,
    } = this.props;
    const value = posMaterials ? posMaterials[position] : null;
    const valuePosMaterial = getPosMaterial(value);
    return (
      <PopoverContent
        width={250}
        title={Types.POS_MATERIAL_POSITION_LABELS_MAP[position]}
        actions={(
          <Actions>
            {
              ['front', 'back'].map((name) => {
                if (!valuePosMaterial) {
                  return null;
                }
                const { type: posMaterialType } = valuePosMaterial;
                const posMaterialConfig = Types.POS_MATERIAL_TYPE_MAP[
                  posMaterialType
                ];
                if (!posMaterialConfig) {
                  return null;
                }
                if (name === 'back' && !posMaterialConfig.back) {
                  return null;
                }
                const image = (
                    valuePosMaterial.image
                    && valuePosMaterial.image[name]
                  ? valuePosMaterial.image[name].src
                  : null
                );
                return (
                  <Preview
                    key={name}
                    src={image}
                  />
                );
              })
            }
          </Actions>
        )}
      >
        {/* <CountriesInput
          value={this.state.countries}
          onChange={this.handleCountriesChange}
        /> */}
        <Query
          client={this.props.client}
          extractData={this.extractQueryData}
          url={this.getQueryUrl()}
        >
          {({ data }) => {
            const projectGroup = [];
            const libraryGroup = [];
            const choices = [
              ...this.getProjectPosMaterials(),
              ...data,
            ];
            choices.forEach((posMaterial) => {
              const option = (
                <Select.Option
                  key={posMaterial._id}
                  value={posMaterial._id}
                >
                  {posMaterial.name}
                </Select.Option>
              );
              if (posMaterial.project) {
                projectGroup.push(option);
              } else {
                libraryGroup.push(option);
              }
            });
            return (
              <Select
                allowClear
                onSearch={this.handleSearchChange}
                value={value || undefined}
                onChange={(posMaterialId) => {
                  if (posMaterialId) {
                    const posMaterial = choices.find(({ _id }) => (
                      posMaterialId === _id
                    ));
                    this.handleChange(posMaterial);
                  } else {
                    this.handleChange(null);
                  }
                }}
                showSearch
                filterOption={false}
                placeholder="Search by name or country"
                disabled={disabled}
              >
                <Select.OptGroup label="Project">
                  {projectGroup}
                </Select.OptGroup>
                <Select.OptGroup label="Library">
                  {libraryGroup}
                </Select.OptGroup>
                {
                  valuePosMaterial
                  ? (
                      <Select.Option
                        value={valuePosMaterial._id}
                        style={{ display: 'none' }}
                      >
                        {valuePosMaterial.name}
                      </Select.Option>
                    )
                  : null
                }
              </Select>
            );
          }}
        </Query>
        <AntdButton.Group style={{ display: 'flex' }}>
          <AntdButton
            style={{ width: '100%', pointerEvents: 'none' }}
          >
            Apply
          </AntdButton>
          <AntdButton
            disabled={!supportsApplyHorizontally}
            onClick={() => onApplyInDirection(
              'HORIZONTAL',
              position,
              value,
            )}
          >
            <LeftOutlined />
            <RightOutlined />
          </AntdButton>
          <AntdButton
            disabled={!supportsApplyVertically}
            onClick={() => onApplyInDirection(
              'VERTICAL',
              position,
              value,
            )}
          >
            <UpOutlined />
            <DownOutlined />
          </AntdButton>
        </AntdButton.Group>
      </PopoverContent>
    );
  }

  renderIcon() {
    const { position } = this.props;
    const { value: { posMaterials } } = this.props;
    const value = posMaterials ? posMaterials[position] : null;
    return (
      <Icon data-active={!!value}>
        {this.props.icon || this.props.position[0]}
      </Icon>
    );
  }

  render() {
    const {
      toolbarItemId,
      toolbarItemIdOpen,
      onOpen,
      onClose,
    } = this.props;
    return (
      <Popover
        title={null}
        content={this.renderContent()}
        trigger="hover"
        destroyTooltipOnHide
        mouseLeaveDelay={0.3}
        placement="bottom"
        visible={toolbarItemId === toolbarItemIdOpen}
        onVisibleChange={visible => (
          visible
          ? onOpen(toolbarItemId)
          : onClose(toolbarItemId)
        )}
      >
        {this.renderIcon()}
      </Popover>
    );
  }
}

export default function POSMaterialPosition(props) {
  const {
    content: { posMaterial: projectPosMaterialsMap = {} },
    getPosMaterial,
  } = useContext(EditorContext);
  const posMaterialType = Types.POS_MATERIAL_POSITION_TYPE_MAP[props.position];
  const projectPosMaterials = useMemo(
    () => (
      Object.keys(projectPosMaterialsMap)
      .reduce(
        (agr, posMaterialId) => {
          const posMaterial = projectPosMaterialsMap[posMaterialId];
          if (posMaterial && posMaterial.type === posMaterialType) {
            agr.push(projectPosMaterialsMap[posMaterialId]);
          }
          return agr;
        },
        [],
      )
      .sort((a, b) => (
          a.name > b.name ? 1
        : b.name > a.name ? -1
        : 0
      ))
    ),
    [projectPosMaterialsMap, posMaterialType],
  );
  return (
    <POSMaterialPositionComponent
      {...props}
      projectPosMaterials={projectPosMaterials}
      getPosMaterial={getPosMaterial}
    />
  );
}
