/* global window */

import React from 'react';
import styled from 'styled-components';

import {
  LikeOutlined,
  LockOutlined,
  LoadingOutlined,
  BulbOutlined,
} from '@ant-design/icons';

import Affix from 'antd/lib/affix';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import Admin from 'hive-admin';
import PageArchiveTable from 'hive-admin/src/components/PageArchiveTable';

import { Report } from '../../components/PageSingleProject';
import { Header } from '../../components/PageSingleProject/Report/common';

import tests from '../../helpers/tests';

import {
  ALL as COLUMNS_ALL,
} from './columns';

import {
  ALL as FILTERS_ALL,
} from './filters';

import {
  CREATE as FIELDS_CREATE,
  CONTENT as FIELDS_CONTENT,
  VARIATIONS as FIELDS_VARIATIONS,
  PLANOGRAM as FIELDS_PLANOGRAM,
  FINALIZE as FIELDS_FINALIZE,
} from './fields';

import {
  actionSaveAndReload,
  actionSaveContent,
  actionSaveVariations,
  actionDuplicate,
  actionDelete,

  actionSwitchToContentBack,
  actionSwitchToVariations,
  actionSwitchToVariationsBack,
  actionSwitchToPlanogram,
  actionSwitchToPlanogramBack,
  actionSwitchToFinalize,
  actionSwitchToReport,
  actionUnsubmit,
  actionUnpublishReport,
  actionPreapprove,
  actionProcessing,
  actionDownloadSessions,
} from './actions';

const { defaultProps: { getColumns: initialGetColumns } } = PageArchiveTable;

const ReportFilterFieldsRow = styled(Row)`
  background: white;
  padding-top: 20px;
  .ant-affix & {
    border-bottom: 1px solid ${({ theme }) => theme.less.borderColor};
  }
`;

const ProcessingFieldsWrapper = styled(Row)`
  margin: 20px 0px;
`;

const ProcessingFieldsCol = styled(Col)`

`;

const ProcessingFieldsRow = styled(Row)`
  background: white;
  padding: 30px;
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border: 1px solid ${({ theme }) => theme.less.borderColor};
  .ant-row.ant-form-item {
    margin-bottom: 10px;
  }
`;

const ReportAnalysis = styled.div`
  white-space: pre-line;
  margin-top: 30px;
`;

ProcessingFieldsCol.defaultProps = {
  sm: { span: 24, offset: 0 },
  md: { span: 24, offset: 0 },
  lg: { span: 16, offset: 4 },
  xl: { span: 12, offset: 6 },
  xxl: { span: 8, offset: 8 },
};

const skipIfViewerCanNotAccessProjects = [
  'condition.not',
  ['condition.or', [
    tests.viewerIsAdmin,
    tests.isViewerCustomerWhichCanManageOrganization,
    tests.isViewerCustomerWhichCanManageProjects,
    tests.isViewerCustomerWhichCanCreateProjects,
    tests.isViewerCustomerWhichCanReadProjects,
  ]],
];

const PAGES = [{
  path: '/projects/:id/content',
  // loadUrl: '/projects/:id/?full=true',
  step: {
    title: 'Content',
    description: 'Setup custom test content',
    onClick: props => props.history.replace(
      `/projects/${props.data._id}/content`
    ),
    getIcon: props => (
      props.data.status === 'DRAFT'
      ? <span>1</span>
      : <LockOutlined />
    ),
    getStatus: props => (
      props.data.status === 'DRAFT'
      ? undefined
      : 'finish'
    ),
    renderContent: (props, renderForm, renderResult) => (
      renderResult(
        'Content',
        'Setup custom test content.',
        null,
        renderForm(),
        { disabled: props.data.status !== 'DRAFT' },
      )
    ),
  },
  fields: FIELDS_CONTENT,
  actions: [
    actionSaveContent,
    actionDelete,
    actionSwitchToVariations,
  ],
}, {
  icon: 'project',
  path: '/projects/:id/variations',
  step: {
    title: 'Variations',
    description: 'Compare between multiple scenarios',
    onClick: props => props.history.replace(
      `/projects/${props.data._id}/variations`
    ),
    getIcon: props => (
      props.data.status === 'DRAFT'
      ? <span>2</span>
      : <LockOutlined />
    ),
    getStatus: props => (
      props.data.status === 'DRAFT'
      ? undefined
      : 'finish'
    ),
    renderContent: (props, renderForm, renderResult) => {
      const { data: { content } } = props;
      const hasContent = true || (
        content.display
        || (content.products && content.products.length)
        || (content.posMaterials && content.posMaterials.length)
      );
      return renderResult(
        'Variations',
        (
          hasContent
          ? (
              <>
                Compare between multiple scenarios.
                <br />
                Please see our Test image guidelines&nbsp;
                <a
                  rel="noopener noreferrer"
                  // eslint-disable-next-line max-len
                  href="https://shopnosis.slite.com/p/note/B8i9xf8HMa6Wgfys2yrbQS"
                  target="_blank"
                >
                  here
                </a>
                .
              </>
            )
          : (
              <>
                To compare between multiple scenarios,
                <br />
                please add some content or enable Secondary Display.
              </>
            )
        ),
        null,
        renderForm(),
        { disabled: props.data.status !== 'DRAFT' },
      );
    },
  },
  fields: FIELDS_VARIATIONS,
  loadExtractData: (response) => {
    if (!response || !response.data) {
      return null;
    }
    if (response.data.variations) {
      return {
        ...response.data,
        variations: response.data.variations.map(
          variation => variation.resources.reduce(
            (agr, { targetType, targetInfo, file }) => {
              agr[`${targetType}-${targetInfo}`] = file;
              return agr;
            },
            { _primary: !!variation.primary },
          ),
        ),
      };
    }
    return response.data;
  },
  actions: [
    actionSwitchToContentBack,
    actionSaveVariations,
    actionDelete,
    actionSwitchToPlanogram,
  ],
}, {
  icon: 'project',
  path: '/projects/:id/planogram',
  loadUrl: '/projects/:id/?full=true',
  step: {
    title: 'Planogram',
    description: 'Setup your test store',
    onClick: props => props.history.replace(
      `/projects/${props.data._id}/planogram`
    ),
    getIcon: props => (
      props.data.status === 'DRAFT'
      ? <span>3</span>
      : <LockOutlined />
    ),
    getStatus: props => (
      props.data.status === 'DRAFT'
      ? undefined
      : 'finish'
    ),
    renderContent: (props, renderForm, renderResult) => (
      renderResult(
        'Planogram',
        'Setup your test store.',
        null,
        renderForm(),
      )
    ),
  },
  fields: FIELDS_PLANOGRAM,
  actions: [
    actionSwitchToVariationsBack,
    actionSaveAndReload,
    actionDelete,
    actionSwitchToFinalize,
    actionSwitchToReport,
  ],
}, {
  icon: 'project',
  path: '/projects/:id/(finalize|report)',
  formProps: {
    renderFields: (fields, form) => {
      if (
           form.props
        && form.props.data
        && form.props.viewer
        && ['PROCESSING', 'REPORT'].includes(form.props.data.status)
        && form.props.viewer.role === 'ADMIN'
      ) {
        const {
          fieldsReportFilter,
          fieldsProcessing,
          fieldsRest,
        } = fields.reduce(
          (agr, field) => {
            if (field.isReportFilterField) {
              agr.fieldsReportFilter.push(field);
            } else if (field.isProcessingField) {
              agr.fieldsProcessing.push(field);
            } else {
              agr.fieldsRest.push(field);
            }
            return agr;
          },
          { fieldsReportFilter: [], fieldsProcessing: [], fieldsRest: [] },
        );
        return (
          <>
            {
              fieldsProcessing.length
              ? (
                  <ProcessingFieldsWrapper>
                    <ProcessingFieldsCol>
                      <ProcessingFieldsRow gutter={10}>
                        <Header
                          title="Generate Report"
                          // eslint-disable-next-line max-len
                          description="Fill in custom analysis and screen the submitted sessions for potential outliers"
                        />
                        {fieldsProcessing.map(field => form.renderField(field))}
                      </ProcessingFieldsRow>
                    </ProcessingFieldsCol>
                  </ProcessingFieldsWrapper>
                )
              : null
            }
            {
              fieldsReportFilter.length
              ? (
                  <Affix target={() => window.document.querySelector('#main')}>
                    <ReportFilterFieldsRow gutter={10}>
                      {fieldsReportFilter.map(field => form.renderField(field))}
                    </ReportFilterFieldsRow>
                  </Affix>
                )
              : null
            }
            {fieldsRest.map(field => form.renderField(field))}
          </>
        );
      }
      // console.log(fields, form);
      return fields.map(field => form.renderField(field));
    },
  },
  step: {
    title: 'Finalize',
    getTitle: (props) => (
        !props.data
      ? 'Finalize'
      : props.data.status === 'LIVE'
      ? 'Live'
      : props.data.status === 'PROCESSING'
      ? 'Processing'
      : props.data.status === 'REPORT'
      ? 'Report'
      : 'Finalize'
    ),
    getDescription: (props) => {
      switch (props.data.status) {
        case 'DRAFT':
          return 'Pusblish and gather reports';
        case 'READY':
          return 'Awaiting payment';
        case 'QUOTING':
          return 'Awaiting custom pricing';
        case 'APPROVAL':
          return 'Awaiting final approval';
        case 'APPROVED':
          return 'Will go live shortly';
        case 'LIVE':
          return 'Testing in progress';
        case 'PROCESSING':
          return 'Compiling results';
        case 'REPORT':
          return 'Study test insights';
        default:
          return 'Processing...';
      }
    },
    getRedirect: (props) => {
      if (props.data && props.match) {
        const { data: { _id: projectId, status }, match: { params } } = props;
        if (status === 'REPORT' && params['0'] !== 'report') {
          return `/projects/${projectId}/report`;
        }
        if (status !== 'REPORT' && params['0'] === 'report') {
          return `/projects/${projectId}/finalize`;
        }
      }
      return null;
    },
    onClick: props => props.history.replace(
      `/projects/${props.data._id}/${
          props.data && props.data.status === 'REPORT'
        ? 'report'
        : 'finalize'
      }`,
    ),
    getIcon: ({ data: { status } }) => (
        status === 'DRAFT'
      ? <LikeOutlined />
      : status === 'REPORT'
      ? <BulbOutlined />
      : <LoadingOutlined />
    ),
    getStatus: props => (
      props.data.status === 'DRAFT'
      ? 'wait'
      : 'finish'
    ),
    renderContent: (props, renderForm, renderResult) => {
      const { data, viewer } = props;
      const cannotManageProject = (
        viewer.role === 'CUSTOMER'
        && !viewer.permissions.manageOrganization
        && !viewer.permissions.manageProjects
      );
      if (
        cannotManageProject
        && data.status !== 'DRAFT'
        && data.status !== 'REPORT'
      ) {
        return renderResult(
          'Submitted',
          'Project is being processed at this time, please check back later.',
          'loading',
        );
      }
      switch (data.status) {
        case 'DRAFT':
          return renderResult(
            'Submit',
            cannotManageProject
            ? 'Please consult your project manager about further steps'
            : 'When you\'re ready to submit, click the button below',
            null,
            renderForm(),
          );
        case 'READY':
          return renderResult(
            'Payment',
            (
              <>
                <br />
                See pricing details at:&nbsp;
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://shopnosis.io/plans"
                >
                  https://shopnosis.io/plans
                </a>
                .
                <br />
                Shopnosis.io engages a PCI DSS certified
                <br />
                third party to process credit/debit card payments
                and Shopnosis.io does not
                <br />
                collect or retain credit/debit card information.
                <br />
              </>
            ),
            null,
            renderForm(),
          );
        case 'QUOTING':
          return (
              !viewer
            ? null
            : viewer.role === 'ADMIN'
            ? renderResult(
                'Custom Pricing',
                (
                  <>
                    Customer has requested a custom pricing
                    <br />
                    for this project.
                  </>
                ),
                null,
                renderForm(),
              )
            : viewer.role === 'CUSTOMER'
            ? renderResult(
                'Custom Pricing',
                // eslint-disable-next-line max-len
                'Request sent. Please wait while our team evaluates your proposal.',
                'loading',
              )
            : null
          );
        case 'APPROVAL':
          return (
              !viewer
            ? null
            : viewer.role === 'ADMIN'
            ? renderResult(
                'Approval',
                (
                  <>
                    Payment for this project has been authorized.
                    <br />
                    Perform the final review before choosing
                    the appropriate action.
                  </>
                ),
                'loading',
                renderForm(),
              )
            : viewer.role === 'CUSTOMER'
            ? renderResult(
                'Awaiting Final Approval',
                null,
                'loading',
              )
            : null
          );
        case 'APPROVED':
          return (
              !viewer
            ? null
            : viewer.role === 'ADMIN'
            ? renderResult(
                'Approved',
                null,
                'like',
                renderForm(),
              )
            : viewer.role === 'CUSTOMER'
            ? renderResult(
                'Approved',
                'We\'ll notify you once the project goes live.',
                'loading',
              )
            : null
          );
        case 'LIVE':
          return (
              !viewer
            ? null
            : viewer.role === 'ADMIN'
            ? renderResult(
                'Live',
                data.testing
                ? (
                    <>
                      <br />
                      <div>
                        Gathered data from
                      </div>
                      <div style={{ fontSize: '22px', fontWeight: 'bold' }}>
                        {`${
                          data.testing.sessions.total
                        } / ${
                          data.testing.sample.total
                        }`}
                      </div>
                      <div>
                        shoppers so far.
                      </div>
                    </>
                  )
                : null,
                'loading',
                renderForm(),
              )
            : viewer.role === 'CUSTOMER'
            ? renderResult(
                'Live',
                null,
                'loading',
              )
            : null
          );
        case 'PROCESSING':
          return (
              !viewer
            ? null
            : viewer.role === 'ADMIN'
            ? renderResult(
                null,
                null,
                null,
                <Report key="report" {...props} renderForm={renderForm} />
              )
            : viewer.role === 'CUSTOMER'
            ? renderResult(
                'Processing',
                null,
                'loading',
              )
            : null
          );
        case 'REPORT':
          return (
              !viewer
            ? null
            : renderResult(
                'Report',
                data.analysis
                ? <ReportAnalysis>{data.analysis}</ReportAnalysis>
                : null,
                null,
                <Report key="report" {...props} renderForm={renderForm} />
              )
          );
        default:
          return null;
      }
    },
  },
  actions: [
    actionSwitchToPlanogramBack,
    actionUnsubmit,
    actionUnpublishReport,
    actionDuplicate,
    actionDelete,
    actionPreapprove,
    actionProcessing,
    actionDownloadSessions,
  ],
  fields: FIELDS_FINALIZE,
}];

const STEPS = PAGES.map(({ path, step = {} }, index) => ({
  index,
  ...step,
  path,
}));

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Projects',
    icon: 'project',
    path: '/projects',
    skip: [skipIfViewerCanNotAccessProjects],
    archiveConfig: {
      icon: 'project',
      title: 'Projects',
      label: 'Projects',
      filters: FILTERS_ALL,
      columns: COLUMNS_ALL,
      redirect: [['redirect.unauthorized']],
      getArchiveFilters: (props, filtersOriginal) => (
        filtersOriginal.filter(filter => (
            !filter.isAvailable
          ? true
          : filter.isAvailable(props, filter)
        ))
      ),
      getColumns: props => initialGetColumns(
        props,
        props.columns.filter(column => (
            !column.isAvailable
          ? true
          : column.isAvailable(props, column)
        )),
      ),
      createButtonPath: '/projects/create',
      getCreateButtonSupported: ({ viewer }) => (
        viewer
        && (
          viewer.role === 'ADMIN'
          || (
            viewer.role === 'CUSTOMER'
            && (
              viewer.permissions.manageOrganization
              || viewer.permissions.manageProjects
              || viewer.permissions.createProjects
            )
          )
        )
      ),
    },
    singleCreateConfig: {
      hidden: true,
      alias: '/projects',
      backButtonPaths: ['/projects'],
      headerTitle: 'Create Project',
      fields: FIELDS_CREATE,
      redirect: [['redirect.unauthorized']],
    },
    singleEditConfig: {
      title: 'Project',
      alias: '/projects',
      backButtonPaths: ['/projects'],
      redirect: [
        ['redirect.unauthorized'],
        ({ match: { url } }) => `${url}/content`,
      ],
    },
    beforeSinglePages: [
      ...PAGES.map(({ step, ...config }) => ['PageSingleProject', {
        alias: '/projects',
        backButtonPaths: ['/projects'],
        activity: 'edit',
        loadUrl: '/projects/:id',
        deleteUrl: '/projects/:id',
        deleteRedirectPath: '/projects',
        title: 'Project',
        ...config,
        steps: STEPS,
        redirect: [['redirect.unauthorized']],
      }]),
    ],
  }])
).pages;
