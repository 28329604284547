/* global window */

import React, { Component } from 'react';
import styled from 'styled-components';
import { AnimateSharedLayout } from 'framer-motion';

import {
  UndoOutlined,
  RedoOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';

// import Admin from 'hive-admin';

import { Provider as EditorProvider } from './Context';

import {
  Provider as DNDProvider,
} from './DND';

import {
  Provider as TabRemovedProvider,
} from './Sidebar/TabRemoved';

import Sidebar from './Sidebar';

import Shelfs from './Shelfs';

import {
  Item as ToolbarItem,
  ItemSection as ToolbarItemSection,
  ItemVariations as ToolbarItemVariations,
  ItemPreview as ToolbarItemPreview,
  ItemPlanogramExport as ToolbarItemPlanogramExport,
  ItemLoad as ToolbarItemLoad,
  ItemReplace as ToolbarItemReplace,
} from './Toolbar';

import Types from '../../../modules/types';

import {
  HEADER_HEIGHT,
  SIDEBAR_WIDTH,
} from '../config';

export * from './Loader';

const Body = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: ${SIDEBAR_WIDTH}px;
  top: 0px;
  right: 0px;
  bottom: 0px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  padding: 0px 10px;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid transparent;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-grow: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export default class Editor extends Component {
  static defaultProps = {
    target: 'library',
    purpose: null,
    historySize: 10,
    draggableAroundActive: 1,
  }

  static getResourcesChanges(
    itemKey,
    itemId,
    resourcesChanges,
    resources,
    isNew,
    itemInput,
  ) {
    const propertyKeys = ['category', 'company', 'brand'];
    if (!resourcesChanges[itemKey]) {
      resourcesChanges[itemKey] = {};
    }
    if (!resourcesChanges[itemKey][itemId]) {
      resourcesChanges[itemKey][itemId] = {
        _id: itemId,
        data: null,
        refs: 0,
      };
    }
    let item = itemInput;
    if (!isNew || !item) {
      item = resources[itemKey][itemId].data;
    } else {
      // isNew && item
      item = resourcesChanges[itemKey][itemId].data = { ...item };
    }
    resourcesChanges[itemKey][itemId].refs += (
      1 * (isNew ? 1 : -1)
    );
    for (
      let propertyKeyIndex = 0;
      propertyKeyIndex < propertyKeys.length;
      propertyKeyIndex++
    ) {
      const propertyKey = propertyKeys[propertyKeyIndex];
      let propertyValue = null;
      let propertyValueId = item[propertyKey];
      if (propertyValueId) {
        if (propertyValueId._id) {
          propertyValue = propertyValueId;
          propertyValueId = propertyValue._id;
          item[propertyKey] = propertyValueId;
        }
        if (!resourcesChanges[propertyKey]) {
          resourcesChanges[propertyKey] = {};
        }
        if (!resourcesChanges[propertyKey][propertyValueId]) {
          resourcesChanges[propertyKey][propertyValueId] = {
            _id: propertyValueId,
            data: null,
            refs: 0,
          };
        }
        resourcesChanges[propertyKey][propertyValueId].refs += (
          1 * (isNew ? 1 : -1)
        );
        if (propertyValue) {
          resourcesChanges[propertyKey][propertyValueId].data = propertyValue;
        }
      }
    }
  }

  static getShelfPosMaterialsTarget(
    targetInput,
    content,
    resources,
    resourcesChanges = {},
    isNew = false,
  ) {
    let target = targetInput;
    for (
      let posMaterialPositionIndex = 0;
      posMaterialPositionIndex < Types.POS_MATERIAL_POSITION.length;
      posMaterialPositionIndex++
    ) {
      const posMaterialPosition = Types.POS_MATERIAL_POSITION[
        posMaterialPositionIndex
      ];
      let posMaterial = null;
      let posMaterialId = target.posMaterials[posMaterialPosition];
      if (posMaterialId) {
        if (posMaterialId._id) {
          posMaterial = posMaterialId;
          posMaterialId = posMaterial._id;
          if (target === targetInput) {
            target = { ...target };
          }
          if (target.posMaterials === targetInput.posMaterials) {
            target.posMaterials = { ...target.posMaterials };
          }
          target.posMaterials[posMaterialPosition] = posMaterialId;
        }
        if (
          !content
          || !content.posMaterial
          || !content.posMaterial[posMaterialId]
        ) {
          this.getResourcesChanges(
            'posMaterial',
            posMaterialId,
            resourcesChanges,
            resources,
            isNew,
            posMaterial,
          );
        }
      }
    }
    return target;
  }

  static getShelf(
    shelfInput,
    content,
    resources,
    resourcesChanges = {},
    isNew = false,
  ) {
    let shelf = shelfInput;
    for (
      let segmentIndex = 0;
      segmentIndex < shelf.segments.length;
      segmentIndex++
    ) {
      const segmentInput = shelf.segments[segmentIndex];
      let segment = segmentInput;
      if (segment.posMaterials) {
        segment = this.getShelfPosMaterialsTarget(
          segment,
          content,
          resources,
          resourcesChanges,
          isNew,
        );
        if (segment !== segmentInput) {
          if (shelf === shelfInput) {
            shelf = { ...shelf };
          }
          if (shelf.segments === shelfInput.segments) {
            shelf.segments = shelf.segments.slice();
          }
          if (segment === shelf.segments[segmentIndex]) {
            segment = { ...segment };
          }
          shelf.segments[segmentIndex] = segment;
        }
      }
      if (segment.groups && segment.groups.length) {
        for (
          let groupIndex = 0;
          groupIndex < segment.groups.length;
          groupIndex++
        ) {
          let group = segment.groups[groupIndex];
          let product = null;
          let productId = group.product;
          if (productId) {
            if (productId._id) {
              product = productId;
              productId = productId._id;
              if (shelf === shelfInput) {
                shelf = { ...shelf };
              }
              if (shelf.segments === shelfInput.segments) {
                shelf.segments = shelf.segments.slice();
              }
              if (segment === shelf.segments[segmentIndex]) {
                segment = { ...segment };
                shelf.segments[segmentIndex] = segment;
              }
              if (group === segment.groups[groupIndex]) {
                group = { ...group };
                segment.groups[groupIndex] = group;
              }
              group.product = productId;
            }
            if (!content || !content.product || !content.product[productId]) {
              this.getResourcesChanges(
                'product',
                productId,
                resourcesChanges,
                resources,
                isNew,
                product,
              );
            }
          }
        }
      }
    }
    if (shelf.posMaterials) {
      shelf = this.getShelfPosMaterialsTarget(
        shelf,
        content,
        resources,
        resourcesChanges,
        isNew,
      );
    }
    return shelf;
  }

  static getResources(resourcesInput, changes = {}) {
    let resources = resourcesInput;
    const changesKeys = Object.keys(changes);
    for (
      let changesKeyIndex = 0;
      changesKeyIndex < changesKeys.length;
      changesKeyIndex++
    ) {
      const changesKey = changesKeys[changesKeyIndex];
      const changesItems = changes[changesKey];
      const changesItemsIds = Object.keys(changesItems);
      for (
        let changesItemIdIndex = 0;
        changesItemIdIndex < changesItemsIds.length;
        changesItemIdIndex++
      ) {
        const changesItemId = changesItemsIds[changesItemIdIndex];
        // console.log(changesKey, changesItemId);
        const changesItem = changesItems[changesItemId];
        if (changesItem.refs !== 0 || changesItem.data) {
          resources = { ...resources };
          resources[changesKey] = { ...resources[changesKey] };
          if (!resources[changesKey][changesItemId]) {
            resources[changesKey][changesItemId] = {
              _id: changesItemId,
              data: null,
              refs: 0,
            };
          } else {
            resources[changesKey][changesItemId] = {
              ...resources[changesKey][changesItemId],
            };
          }
          if (changesItem.data) {
            // TODO Maybe use timestamps to decide which one should be used
            resources[changesKey][changesItemId].data = changesItem.data;
          }
          if (changesItem.refs !== 0) {
            resources[changesKey][changesItemId].refs += changesItem.refs;
            if (!resources[changesKey][changesItemId].refs) {
              delete resources[changesKey][changesItemId];
            }
          }
        }
      }
    }
    return resources;
  }

  static getChangedValue(shelfsNew, shelfsOld, resourcesOld, countent) {
    let shelfs = shelfsNew;
    const shelfIds = Object.keys(shelfsNew);
    const resourcesChanges = {};
    for (
      let shelfIdIndex = 0;
      shelfIdIndex < shelfIds.length;
      shelfIdIndex++
    ) {
      const shelfId = shelfIds[shelfIdIndex];
      if (shelfsNew[shelfId] !== shelfsOld[shelfId]) {
        this.getShelf(
          shelfsOld[shelfId],
          countent,
          resourcesOld,
          resourcesChanges,
          false,
        );
        const shelf = this.getShelf(
          shelfsNew[shelfId],
          countent,
          resourcesOld,
          resourcesChanges,
          true,
        );
        if (shelf !== shelfs[shelfId]) {
          shelfs = {
            ...shelfs,
            [shelfId]: shelf,
          };
        }
      }
    }
    return {
      shelfs,
      message: `Change: ${Math.floor(Math.random() * 100)}`,
      resources: this.getResources(resourcesOld, resourcesChanges),
    };
  }

  static getContent(content = {}, variation = {}) {
    content = { ...content };
    if (content.product) {
      content.product = { ...content.product };
      Object.keys(content.product).forEach((productId) => {
        const image = variation[`PRODUCT-${productId}`];
        content.product[productId] = {
          ...content.product[productId],
          image: image || null,
        };
      });
    }
    if (content.posMaterial) {
      content.posMaterial = { ...content.posMaterial };
      Object.keys(content.posMaterial).forEach((posMaterialId) => {
        const image = {};
        [['front', '_FRONT'], ['back', '_BACK']].forEach(([name, suffix]) => {
          image[name] = (
            variation[`POS_MATERIAL${suffix}-${posMaterialId}`]
            || null
          );
        });
        content.posMaterial[posMaterialId] = {
          ...content.posMaterial[posMaterialId],
          image,
        };
      });
    }
    return content;
  }

  constructor(props) {
    super(props);
    window.EDITOR = this;
    this.state = {
      target: this.props.target,
      value: {
        message: 'Editor open',
        shelfs: { ...this.props.value },
        resources: { ...this.props.resources },
      },
      getProduct: this.getProduct,
      getPosMaterial: this.getPosMaterial,
      getBrand: this.getBrand,
      getCategory: this.getCategory,
    };
    this.state.active = (
      Types.PLANOGRAM
      .list
      .find(({ section }) => (
        section.initial && section.target.includes(this.props.target)
      ))
      .id
    );
    this.state.activeSection = (
      Types.PLANOGRAM
      .map[this.state.active]
      .section
      .id
    );
    this.state.history = {
      steps: [this.state.value],
      index: 0,
    };
    this.initialValue = this.state.value;
    if (this.props.variations) {
      this.state.variation = 0;
      const primaryVariationIndex = this.props.variations.findIndex(
        ({ primary }) => primary === true,
      );
      const primaryVariation = {};
      if (primaryVariationIndex > -1) {
        this.props.variations[primaryVariationIndex]
        .resources
        .forEach(({ targetType, targetInfo, file }) => {
          if (file) {
            primaryVariation[`${targetType}-${targetInfo}`] = file;
          }
        });
      }
      this.state.variations = this.props.variations.map(
        ({ resources = [] }, variationIndex) => {
          const variation = { ...primaryVariation };
          if (primaryVariationIndex !== variationIndex) {
            resources.forEach(({ targetType, targetInfo, file }) => {
              if (file) {
                variation[`${targetType}-${targetInfo}`] = file;
              }
            });
          }
          return variation;
        },
      );
    }
    this.state.content = this.constructor.getContent(
      this.props.content,
      this.state.variations ? this.state.variations[0] : {},
    );
    switch (this.state.target) {
      case 'library':
        this.state.content = { ...(this.props.content || {}) };
      break;
      case 'project':
      break;
      default:
    }
  }

  didChange = () => this.state.value !== this.initialValue

  getProduct = (productOrProductId) => {
    const {
      content: { product: contentProducts },
      value: { resources: { product: embeddedProducts } },
    } = this.state;
    return (
        !productOrProductId
      ? null
      : productOrProductId._id
      ? productOrProductId
      : contentProducts[productOrProductId]
      ? contentProducts[productOrProductId]
      : embeddedProducts[productOrProductId]
      ? embeddedProducts[productOrProductId].data
      : null
    );
  }

  getPosMaterial = (posMaterialOrPosMaterialId) => {
    const {
      content: { posMaterial: contentPosMaterials },
      value: { resources: { posMaterial: embeddedPosMaterials } },
    } = this.state;
    return (
        !posMaterialOrPosMaterialId
      ? null
      : posMaterialOrPosMaterialId._id
      ? posMaterialOrPosMaterialId
      : contentPosMaterials[posMaterialOrPosMaterialId]
      ? contentPosMaterials[posMaterialOrPosMaterialId]
      : embeddedPosMaterials[posMaterialOrPosMaterialId]
      ? embeddedPosMaterials[posMaterialOrPosMaterialId].data
      : null
    );
  }

  getBrand = (brandOrBrandId) => {
    const { value: { resources: { brand: embeddedBrands } } } = this.state;
    return (
        !brandOrBrandId
      ? null
      : brandOrBrandId._id
      ? brandOrBrandId
      : embeddedBrands[brandOrBrandId]
      ? embeddedBrands[brandOrBrandId].data
      : null
    );
  }

  getCategory = (categoryOrCategoryId) => {
    const {
      value: { resources: { category: embeddedCategories } },
    } = this.state;
    return (
        !categoryOrCategoryId
      ? null
      : categoryOrCategoryId._id
      ? categoryOrCategoryId
      : embeddedCategories[categoryOrCategoryId]
      ? embeddedCategories[categoryOrCategoryId].data
      : null
    );
  }

  getShopperData(extras = {}) {
    // const keys = ['category', 'company', 'brand', 'product', 'posMaterial'];
    const { content, variations } = this.props;
    const { variation, value: { shelfs } } = this.state;
    const data = {
      variation,
      content: {},
      variations,
      // resources: {},
      purpose: this.props.purpose,
      planogram: shelfs,
      region: this.props.region,
      ...extras,
    };
    if (content) {
      data.content.priceTagColor = content.priceTagColor;
      data.content.mission = content.mission;
      data.content.review = content.review;
      data.content.display = !!content.display;
      if (content.product) {
        data.content.products = Object.values(content.product);
      }
      if (content.posMaterial) {
        data.content.posMaterials = Object.values(content.posMaterial);
      }
    }
    return data;
  }

  getPreview = async (extras = {}) => {
    const result = await this.props.client.request({
      url: '/shopper/preview',
      method: 'POST',
      data: {
        ...this.getShopperData(extras),
        target: this.props.target,
        targetId: this.props.targetId,
      },
    });
    if (result && result.data && result.data.name) {
      const shopperContentUrl = this.props.client.prefix(
        `storage/shopper/preview/${result.data.name}`,
      );
      const shopperPreviewUrl = `${
        process.env.REACT_APP_SHOPPER_URL
      }/${
        result.data.userId
      }/${
        this.props.target
      }/${
        this.props.targetId
      }/${
        result.data.expires
      }`;
      return {
        content: shopperContentUrl,
        preview: shopperPreviewUrl,
      };
    }
    return null;
  }

  getPlanogramExport = async (extras = {}) => {
    const result = await this.props.client.request({
      url: '/shopper/preview',
      method: 'POST',
      data: {
        ...this.getShopperData(extras),
        target: this.props.target,
        targetId: this.props.targetId,
        targetPurpose: 'export',
      },
    });
    if (result && result.data && result.data.expires) {
      return {
        export: this.props.client.prefix(
          `/shopper/export/${result.data.expires}?access_token=${
            this.props.client.getAccessToken()
          }&target=${
            this.props.target
          }&targetId=${
            this.props.targetId
          }`,
        ),
      };
    }
    return null;
  }

  handleClose = () => this.props.onClose()

  handleSaveAndClose = () => this.props.onSaveAndClose(
    this.state.value.shelfs,
    this.state.value.resources,
  )

  handleHistoryMove = (delta) => {
    const {
      history: { steps, index },
      // value: valueCur,
    } = this.state;
    const newIndex = index + delta;
    if (newIndex >= 0 && newIndex < steps.length) {
      // const valueNew = steps[newIndex];
      // const message = (
      //     delta < 0
      //   ? `Undo: ${valueCur.message}`
      //   : delta > 0
      //   ? `Redo: ${valueNew.message}`
      //   : null
      // );
      // if (message) {
      //   Admin.showMessage(message, 'info');
      // }
      this.setState({
        value: steps[newIndex],
        history: { steps, index: newIndex },
      });
    }
  }

  setActive = value => this.setState({
    active: value,
    activeSection: Types.PLANOGRAM.map[value].section.id,
  })

  setActiveIndex = (index = 0, relative = false) => {
    const { active, activeSection } = this.state;
    const { section: { [activeSection]: { list } } } = Types.PLANOGRAM;
    if (relative) {
      const activeIndex = list.indexOf(active);
      this.setState({
        active: list[
          (list.length + (activeIndex + index)) % list.length
        ],
      });
    } else if (index >= 0 && index < list.length) {
      this.setState({ active: list[index] });
    }
  }

  setActiveSection = (value) => {
    const section = Types.PLANOGRAM.section[value];
    const active = section.reverse
    ? section.list[section.list.length - 1]
    : section.list[0];
    this.setState({ active, activeSection: value });
  }

  setVariation = index => this.setState(state => ({
    variation: index,
    content: this.constructor.getContent(
      this.props.content,
      state.variations ? state.variations[index] : {},
    ),
  }))

  handleChange = shelfs => this.setState((state) => {
    const value = this.constructor.getChangedValue(
      shelfs,
      state.value.shelfs,
      state.value.resources,
      state.content,
    );
    const history = {};
    if (state.history.index !== state.history.steps.length - 1) {
      history.steps = [
        ...state.history.steps.slice(state.history.index).reverse(),
        value,
      ].slice(-this.props.historySize);
      history.index = history.steps.length - 1;
    } else {
      history.steps = [
        ...state.history.steps,
        value,
      ].slice(-this.props.historySize);
      history.index = history.steps.length - 1;
    }
    return {
      ...state,
      value,
      history,
    };
  })

  handleChangeShelf = (shelfId, shelf = {}) => this.handleChange({
    ...this.state.value.shelfs,
    [shelfId]: {
      ...this.state.value.shelfs[shelfId],
      ...shelf,
    },
  })

  handleChangeActiveShelf = (shelf) => {
    this.handleChange({
      ...this.state.value.shelfs,
      [this.state.active]: {
        ...this.state.value.shelfs[this.state.active],
        ...shelf,
      },
    });
  }

  render() {
    const {
      client,
      viewer,
      target,
      purpose,
      region,
      currency,
      organization,
      draggableAroundActive,
    } = this.props;
    const {
      active,
      activeSection,
      history,
      value,
      content: {
        display: supportsDisplay = false,
        priceTagColor,
      },
    } = this.state;
    const { shelfs } = value;
    return (
      <EditorProvider value={this.state}>
        <AnimateSharedLayout>
          <TabRemovedProvider>
            <DNDProvider>
              <Sidebar
                client={client}
                target={target}
                region={region}
                currency={currency}
                organization={organization}
                getBrand={this.getBrand}
                getCategory={this.getCategory}
                disabled={this.props.disabled}
              />
              <Body className="planogram-editor-body">
                <Header className="planogram-editor-body-header">
                  <HeaderSection>
                    <ToolbarItemSection
                      key="section"
                      value={activeSection}
                      onChange={this.setActiveSection}
                      target={target}
                      supportsDisplay={supportsDisplay}
                      purpose={purpose}
                    />
                    {
                      this.props.target === 'project'
                      ? (
                          <>
                            <ToolbarItemVariations.Separator />
                            <ToolbarItemVariations
                              key="variations"
                              value={this.state.variation}
                              variations={this.state.variations}
                              onChange={this.setVariation}
                            />
                          </>
                        )
                      : null
                    }
                  </HeaderSection>
                  <HeaderSection>
                    <ToolbarItem
                      key="undo"
                      Icon={UndoOutlined}
                      onClick={() => this.handleHistoryMove(-1)}
                      disabled={history.index === 0}
                      tooltip="Undo"
                    />
                    <ToolbarItem
                      key="redo"
                      Icon={RedoOutlined}
                      onClick={() => this.handleHistoryMove(1)}
                      disabled={history.index === history.steps.length - 1}
                      tooltip="Redo"
                    />
                    <ToolbarItemPreview
                      key="preview"
                      value={value}
                      variation={this.state.variation}
                      getPreview={this.getPreview}
                      tooltip="Preview in store"
                    />
                    {
                      viewer
                      && ['ADMIN', 'CONTENT_MANAGER'].includes(viewer.role)
                      ? (
                          <ToolbarItemPlanogramExport
                            key="planogram-export"
                            value={value}
                            variation={this.state.variation}
                            getPlanogramExport={this.getPlanogramExport}
                            tooltip="Export Planogram"
                          />
                        )
                      : null
                    }
                    <ToolbarItemLoad
                      key="load"
                      client={this.props.client}
                      activeSection={activeSection}
                      onChange={this.handleChange}
                      region={region}
                      organization={organization}
                      value={shelfs}
                      disabled={(
                        this.props.disabled
                        || activeSection === 'DISPLAY'
                      )}
                      tooltip="Load planogram from library"
                    />
                    <ToolbarItemReplace
                      key="random"
                      mode="random"
                      client={this.props.client}
                      activeSection={activeSection}
                      onChange={this.handleChange}
                      region={region}
                      organization={organization}
                      value={shelfs}
                      disabled={this.props.disabled}
                      tooltip="Fill section with random products"
                    />
                    <ToolbarItemReplace
                      key="empty"
                      mode="empty"
                      client={this.props.client}
                      activeSection={activeSection}
                      onChange={this.handleChange}
                      region={region}
                      organization={organization}
                      value={shelfs}
                      disabled={this.props.disabled}
                      tooltip="Clear current section"
                    />
                    <ToolbarItem
                      key="ok"
                      Icon={CheckOutlined}
                      onClick={this.handleSaveAndClose}
                      disabled={this.props.disabled || !this.didChange()}
                      tooltip="Apply changes and close"
                    />
                    <ToolbarItem
                      key="cancel"
                      Icon={CloseOutlined}
                      onClick={this.handleClose}
                      tooltip="Discard changes and close"
                    />
                  </HeaderSection>
                </Header>
                <Shelfs
                  target={this.props.target}
                  client={this.props.client}
                  active={active}
                  draggableAroundActive={draggableAroundActive}
                  value={shelfs}
                  region={region}
                  currency={currency}
                  organization={organization}
                  onSetActive={this.setActive}
                  onSetActiveIndex={this.setActiveIndex}
                  onChange={this.handleChange}
                  onChangeShelf={this.handleChangeShelf}
                  onChangeActiveShelf={this.handleChangeActiveShelf}
                  getProduct={this.getProduct}
                  getBrand={this.getBrand}
                  getCategory={this.getCategory}
                  priceTagColor={priceTagColor}
                  disabled={this.props.disabled}
                />
              </Body>
            </DNDProvider>
          </TabRemovedProvider>
        </AnimateSharedLayout>
      </EditorProvider>
    );
  }
}
